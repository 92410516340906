import React from "react";
import { Helmet } from 'react-helmet'
import "./App.scss"
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { LandingForm } from "../../components/landingForm"
import imgLogo from "./assets/img/logo-ucc.webp";
import imgBanner from "./assets/img/hero/ecologicas.webp";
import imgCalendar from "./assets/img/icons/calendar.webp";
import imgPin from "./assets/img/icons/pin.webp";
import imgReloj from "./assets/img/icons/reloj.webp";
import imgDinero from "./assets/img/icons/dinero.webp";

const EcoLogicas = () => {
	return (
		<>
			<Helmet>
				<script>
				{`(function(w,d,s,l,i)
					{w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});
					var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
					j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-PLKH83F');`}
				</script>

				<noscript>
				{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PLKH83F"
						height="0" width="0" style="display:none;visibility:hidden"> 
					</iframe>`}
				</noscript>
			</Helmet>
			<Navbar sticky="top" bg="azul" className="navbar-landing">
				<Container
					style={{
						justifyContent: "start",
					}}
				>
					<Navbar.Brand>
						<img
							src={imgLogo}
							width="100%"
							height="100%"
							alt="Diplomado: eco lógicas proyectuales. Mecanismos de integración de tecnologías sustentables al proyecto."
						/>
					</Navbar.Brand>
				</Container>
			</Navbar>
			<main>
				<section
					className="hero hero-azul d-flex align-items-center"
					style={{ backgroundImage: `url(${imgBanner})` }}
				>
					<Container className="position-relative">
						<Row>
							<Col className="gap-2 d-flex flex-column">
								<div className="d-flex align-items-center gap-2 gap-md-3">
									<h3
										className="bg-white text-azul fw-light m-0"
										style={{ padding: '0.8rem' }}
									>
										Diplomatura{" "}
										<span className="fw-extrabold">
											Online
										</span>
									</h3>{" "}
									<h4 className="m-0 fw-light text-uppercase text-white">
										Inicio{" "}
										<span className="d-block fw-extrabold">
											6 de abril
										</span>
									</h4>
								</div>
								<h1 className="long-title">Diplomado: eco lógicas proyectuales. Mecanismos de integración de tecnologías sustentables al proyecto.</h1>
								<h5 className="text-white text-uppercase fw-semibold">
									Primera Universidad Privada de Argentina
								</h5>
								<a
									href="#formulario"
									className="btn btn-hero btn-hero-azul shadow-sm d-flex align-self-center align-self-md-start smooth"
								>
									Inscríbete ahora
								</a>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="descripcion" className="bg-light-landing">
                    <Container>
                        <Row>
                            <Col md={6} className="pt-5 pb-3 py-md-6">
								<h3 className="title text-azul">
									Consúltanos{" "}
									<span className="d-block">
										por este curso
									</span>
								</h3>
								<p className="p-style">
                                    Las estrategias sustentables son ineludibles en nuestro contexto actual, tanto nacional como internacional, pero debemos reconocer cuán efectivas o eficientes son estas estrategias y entender que antes de aplicarlas debe haber una reflexión social, cultural, contextual, económica, etc. Toda materia o tecnología puede ser sustentable o todo lo contrario. Sustentable son las ideas, no los detalles constructivos.
								</p>
								<p className="p-style">
                                    Entender la aplicación de las tecnologías en respuesta al diseño y no como condicionantes, demostrando que un detalle o la elección de una metería pueden determinar o delinear la idea de diseño.
								</p>
								<p className="p-style">
                                    Entender las estrategias Bioclimáticas como el inicio de todo proyecto sustentable y ponderando en todo diseño el sentido común implícito en la profesión.
								</p>
								<p className="p-style">
                                    Todo lo construido tiene un área de influencia ya sea social, ecológica, tecnológica, visual, etc. Es de suma importancia entender cuál es el verdadero resultado de lo construido en relación a los usos humanos, a las sombras, a los vientos, la energía, los reflejos, las visuales, etc.
								</p>
								<p className="p-style">
                                    Entender que el contexto es el factor más importante y determinante del proyecto, pudiendo desencadenar en futuras decisiones a pequeña o gran escala.
								</p>
								<p className="p-style">
                                    Diseñado y organizado por la Facultad de Arquitectura.
								</p>
								<p className="p-style">
                                    Se otorgará certificación digital oficial de la Universidad Católica de Córdoba, con opción para adquirir la certificación en papel.
								</p>

								<h5 className="p-style fw-bold">Docentes:</h5>
								<ul>
									<li>Mgter. Imwinkelried, Ignacio José (Director)</li>
									<li>Mgter. Dinardi, Matias Andres (Coordinador)</li>

								</ul>
                                </Col>
                            
                            <Col md={6} className="pt-5 pb-6 py-md-6 px-md-4" id="formulario">   
								<div className='container px-4'>
									<LandingForm nomCurso="Eco Logicas"/>
								</div>                           
                            </Col>
                        </Row>
					</Container>
				</section>
				<section className="position-relative">
					<a href="#resume" className="smooth icon-down">
						<FontAwesomeIcon icon={faArrowDown} style={{ fontSize: 40, width: 100 }} className="bi bi-arrow-down-short d-flex justify-content-center pt-2 text-azul"/>						
					</a>
					<Container>
						<Row id="resume" className="justify-content-center">
							<Col
								sm={11}
								className="bg-white rounded-4 shadow-sm mt-n4 mb-5"
								style={{ zIndex: 1 }}
							>
								<Row className="row-cols-2 row-cols-md-4 py-2 py-md-0">
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img
											src={imgCalendar}
											alt="Fecha de inicio"
										/>
										<div>
											<span className="d-block fw-medium">
												6 de Abril
											</span>
										</div>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img src={imgPin} alt="Ubicación" />
										<span className="text-capitalize fw-medium">
											- Campus UCC <br />- Videoconferencias
										</span>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img
											src={imgReloj}
											alt="Duración del diplomado"
										/>
										<span className="text-capitalize fw-medium">
											3 meses
											<br />
											120 horas totales.
										</span>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img src={imgDinero} alt="Precio" />
										<span className="text-capitalize fw-medium">
											Público en general <br />
											USD 683
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="info">
					<Container className=" pt-4 pb-5 pb-md-6">
						<Row className="mb-4">
							<Col className="text-center">
								<h3 className="title text-azul">
									Objetivos del curso
								</h3>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col md={6}>
								{" "}
								<p className="p-style">
                                    Desarrollar la capacidad del alumno para tomar decisiones de proyecto basadas en criterios que incluyan estrategias y tecnologías bioclimáticas.
								</p>
								<p className="p-style">
                                    Incorporar las variables bioclimáticas como aspectos esenciales del diseño.
								</p>
								<p className="p-style">
                                    Agudizar el espíritu crítico de selección de materias y tecnologías a la hora de diseñar.
								</p>
							</Col>
							<Col md={6}>
								<p className="p-style">
                                    Comprender cuáles son las verdaderas cualidades de las materias a disposición.
								</p>
								<p className="p-style">
                                    Evaluar cómo el proyecto contribuye al confort y la adaptabilidad del usuario.
								</p>
								<p className="p-style">
                                    Evaluar cómo el proyecto mejora la eficiencia energética del edificio a diseñar.
								</p>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col className="text-center">
								<h3 className="title text-azul">
									¿A quién está dirigido?
								</h3>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								{" "}
								<p className="p-style">
                                    Jóvenes profesionales y estudiantes que estén interesados en ampliar sus conocimientos sobre la inclusión de tecnologías sustentables en proyectos y que pertenezcan a los siguientes grupos:
								</p>
								<p className="p-style">
                                    Estudiantes de los últimos años de la carrera de arquitectura, diseño industrial, ingeniería o carreras relacionadas.
								</p>
							</Col>
							<Col md={6}>
								{" "}
								<p className="p-style">
                                    Jóvenes profesionales que se desempeñen en áreas afines a la arquitectura, diseño industrial o ingeniería y que deseen complementar sus conocimientos en el tema.
								</p>
								<p className="p-style">
                                    Profesionales de la arquitectura, diseño industrial, ingeniería o carreras relacionadas que deseen actualizar o profundizar sus conocimientos en el uso de tecnologías sustentables en proyectos.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="programa" className="bg-light-landing py-5 py-md-6">
					<Container>
						<Row>
							<Col className="text-center">
								<h3 class="title text-azul mb-3">Contenidos</h3>
							</Col>
						</Row>
						<Row>
							<Col>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="0"
										>
											Introducción / Programa de trabajo / Manual del usuario 
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
                                                En este módulo inicial se hará una presentación general del curso y sus contenidos centrales. Se definirá el enfoque del curso y se presentará el tema del trabajo que se desarrollará a lo largo del diplomado.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="1"
										>
											Módulo 1
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="1">
											<Card.Body>
                                                <h6 className="fw-bold">Medio ambiente (Clima, Asoleamiento / Ecología / Territorio)</h6>
                                                En este módulo se abordarán los temas relacionados a la inserción del proyecto en un medio ambiente determinado y su capacidad de adaptación al territorio. Se trabajará sobre el impacto del proyecto en su contexto de inserción.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="2"
										>
											Módulo 2
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2">
											<Card.Body>
                                                <h6 className="fw-bold">Sistemas constructivos (Estructuras / Materiales / Procesos constructivos)</h6>
                                                Este módulo indagará sobre la eficiencia de los distintos sistemas estructurales a nivel sustentable y el estudio detallado de los materiales y procesos constructivos que estos conllevan. Se estudiará el proceso completo de obra desde sus inicios hasta su final teniendo en cuenta el tratamiento de sobrantes y posibles procesos de reciclaje y reutilización.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="3"
										>
											Módulo 3
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="3">
											<Card.Body>
                                                <h6 className="fw-bold">Envolventes (Horizontales / Verticales)</h6>
                                                Este módulo abordará la resolución de las distintas envolventes y sus características técnicas y constructivas en función del contexto inserción (clima, ecología y territorio) Se capacitará a los estudiantes en cálculos básicos de transmitancia y balance térmico.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="4"
										>
											Módulo 4
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="4">
											<Card.Body>
                                                <h6 className="fw-bold">Infraestructuras (Instalaciones servicios / Reciclaje / Producción)</h6>
                                                Se estudiarán en este módulo los sistemas de instalaciones y redes en el proyecto tanto desde sus funciones de servicios como de reciclaje y producción de energías renovables. Se profundizará en los requerimientos técnicos de cada uno de los sistemas y del impacto que estos tienen en el proyecto. Se hará hincapié en la resolución proyectual de sistemas que permitan reducir el consumo energético.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="5"
										>
											Presentación Proyecto Final / Cierre del curso
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="5">
											<Card.Body>
                                                Cada estudiante realizará una presentación de su Proyecto Final en una exposición que no deberá exceder los 10 minutos. Esto permitirá compartir la producción del curso y se utilizará como evaluación final. Posteriormente, se realizará una conclusión que incluirá una revisión de los contenidos básicos y un balance del curso en general.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</Col>
						</Row>
					</Container>
				</section>
			</main>
            <footer class="bg-azul">
                <Container className="py-5">
                    <Row>
                        <Col className="text-center">
                            <h2>Certificación Oficial de la Universidad Católica de Córdoba</h2>
                        </Col>
                    </Row>

                </Container>

    </footer>
		</>
	);
};

export default EcoLogicas;
